import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, Category, CategoryService, CategoryTypeEnum, ConfirmationComponent, Pager } from 'lib-trend-core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { CategoryAddComponent } from './category-add/category-add.component';

@Component({
  selector: 'category-component',
  templateUrl: './category.component.html',
  styleUrl: './category.component.scss',
})
export class CategoryComponent extends AbstractComponent implements OnInit {

  pager: Pager<Category> = new Pager<Category>({ perPage: 7 });
  listObservable: Observable<Pager<Category>>;

  private termoDaBusca: Subject<string> = new Subject<string>();

  typeCategory: CategoryTypeEnum = CategoryTypeEnum.RESPONSE;

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    private categoryService: CategoryService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getList();
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.categoryService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Category>) => {
      this.pager = pager;
    });
  }

  search(termo: string) {
    this.pager.page = 1;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }

  private getList() {
    this.searchParams = {
      company: super.getIDCurrentCompany(),
    };

    this.categoryService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
      next: (pager: Pager<Category>) => {
        this.pager = pager;
        this.setupObservableSearch();
      },
    });
  }

  add(): void {
    const dialogRef = this.dialog.open(CategoryAddComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList();
    });
  }

  edit(item: Category): void {
    const dialogRef = this.dialog.open(CategoryAddComponent, {
      width: '600px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList();
    });
  }

  // Dont delete if category is linked the a response fast
  delete(item: Category): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.categoryService.delete(item._id).subscribe({
          next: () => {
            this.getList();
            this.alertService.success('Categoria excluída com sucesso.');
          }
        })
      }
    });
  }
}
