<div class="flex flex-1 flex-col mb-[20px]">
  <div class="w-full flex items-center">
  </div>
  <div class="flex gap-2">
  </div>
  <div class="flex flex-col justify-start">
    <div>
      <h1 class="font-black text-[20px] text-black">Configuração do Chatbot</h1>
      <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados para configurar o seu auto atendimento</h6>
    </div>
  </div>
  <div *ngIf="channel">
    <form [formGroup]="formGroup">

      <div class="w-full rounded-lg">
        <div class="main">
          <div class="content-msg flex flex-col h-full shadow-global rounded-lg justify-center p-4 mb-4">
            <div class="flex items-center justify-center ">
              <div class="w-full flex justify-center items-center">
                <div class="input-container">
                  <mat-slide-toggle formControlName="actived" class="mb-3" color="#4213F6">
                    <span class="text-[#000] ml-2 text-[1rem]">
                      {{formGroup.get('actived').value ? 'Chatbot ativado' : 'Chatbot desativado'}}</span>
                  </mat-slide-toggle>
                  <div class="flex flex-col justify-end items-end">
                    <!-- <textarea class="w-full h-[150px] input-field" 
                              formControlName="message" 
                              placeholder="Digite seu conteúdo..." 
                              style="resize: none;">
                    </textarea> -->

                    <div class="textarea-container w-full">
                      <textarea #messageTextarea formControlName="message" class="input-textarea h-[80px]"
                        placeholder="Digite..." (paste)="onPaste($event)"></textarea>

                      <div class="icon-container flex items-center">
                        <mat-icon (click)="applyFormat('bold')" class="cursor-pointer ml-2">format_bold</mat-icon>
                        <mat-icon (click)="applyFormat('italic')" class="cursor-pointer ml-2">format_italic</mat-icon>
                        <mat-icon (click)="applyFormat('underline')"
                          class="cursor-pointer ml-2">format_underline</mat-icon>
                        <mat-icon (click)="toogleContainerEmoji()"
                          class="cursor-pointer ml-2">sentiment_satisfied</mat-icon>
                        <div class="relative" id="emoji-container">
                          <ng-container *ngIf="showContainerEmoji">
                            <emoji-mart (emojiSelect)="addEmoji($event)" [i18n]="translator" [darkMode]="false"
                              class="absolute bottom-300 right-[40px] lg:bottom-100">
                            </emoji-mart>
                          </ng-container>
                        </div>
                        <mat-icon [matMenuTriggerFor]="menuVar" class="cursor-pointer">code</mat-icon>
                      </div>
                    </div>
                    <mat-menu #menuVar="matMenu">
                      <ng-container *ngFor="let variable of templateVariableList">
                        <button mat-menu-item (click)="setVariableInContent(variable.value)">
                          <mat-icon color="primary">{{ variable.icon }}</mat-icon>
                          {{ variable.label }}
                        </button>
                      </ng-container>
                    </mat-menu>
                  </div>
                </div>


              </div>
              <div class="flex justify-between items-center ">
                <div class="flex flex-col">
                  <div
                    class="img-selector bg-[#f7f7f7] border-2 border-gray-300 border-dashed rounded-lg flex flex-col cursor-pointer"
                    (click)="fileInputFile.click()">

                    <loading-component class="loading-chat" *ngIf="loadingSpinner$ | async"></loading-component>
                    <ng-container *ngIf="attachmentChatbot() else defaultView">
                      <img [src]="attachmentChatbot()" alt="Selected Media"
                        class="w-[100%] h-[80px] object-contain rounded-lg" />
                      <p class="mb-2 text-sm text-gray-500 dark:text-gray-400" *ngIf="!chatbot.attachment">
                        <span class="font-semibold text-[#4213F6] text-xl">Clique aqui para carregar</span>
                      </p>
                      <p class="text-xs text-gray-500 dark:text-gray-400" *ngIf="!chatbot.attachment">
                        PNG, JPG, JPEG ou PDF (MAX. 800x400px)
                      </p>
                    </ng-container>
                    <ng-template #defaultView>
                      <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                      </svg>
                    </ng-template>
                    <input #fileInputFile type="file" (change)="onMidiaChange($event)"
                      style="display: none; height: 200px;" />
                    <div class="mt-[10px]">
                      @if (chatbot.attachment) {
                      <button (click)="removeAttachment()" *ngIf="isAdmin()"
                        class="bg-red-600 text-white flex items-center rounded"><mat-icon>clear</mat-icon></button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full">
          <div class=" bg-white shadow-global rounded-lg p-4">
            <div class="flex flex-col justify-start">
              <div>
                <h1 class="font-black text-[20px] text-black">Menu do Chatbot</h1>
                <h6 class="text-zinc-400 font-light text-sm my-2"> Configure as opções em que serão exibidas ao contato.
                  O lead deverá digitar a opção desejada para ser
                  direcionado
                  a um departamento ou conteúdo que você determinar abaixo.</h6>
              </div>
            </div>
            <div class="flex gap-3 ">

              <div class="flex gap-3">
                <div class="flex flex-col">
                  <div class="title-menu flex items-center justify-center">
                    <button (click)="addMenu()" *ngIf="isAdmin()" class="text-white rounded-lg transition-colors">
                      + Adicionar ao menu principal
                    </button>
                  </div>

                  <div class="main-menu-bot rounded-lg shadow-global">
                    <div class="menu w-full">
                      <div class="menu-list p-3">
                        @if (chatbot.menus?.length > 0) {
                        <ul class="relative space-y-3">
                          @for (menu of chatbot.menus; track menu; let index = $index) {
                          <li class="relative">
                            <div class="relative">
                              <div class="relative flex">
                                <div (click)="selectChatbotMenuOption(menu)"
                                  class="w-fit flex justify-between items-center pr-2 pl-2 rounded text-white cursor-pointer transition-colors shadow-sm h-7"
                                  [ngClass]="{
                                  'bg-[#4F46E5] hover:bg-[#4338ca]': menu.actived,
                                  'bg-slate-500 hover:bg-slate-600': !menu.actived,
                                  'bg-[#4338ca]': chatbotMenuIndex === index && menu.actived,
                                  'bg-slate-400': chatbotMenuIndex === index && !menu.actived
                                }">
                                  <span class="flex items-center gap-1 whitespace-nowrap">
                                    <span class="min-w-[14px] font-semibold text-xs">
                                      {{ getDisplayOption(menu.option) }}
                                    </span>
                                    <span class="text-xs">{{ menu.title }}</span>
                                  </span>
                                </div>
                              </div>

                              @if (menu.menus?.length > 0) {
                              <div class="absolute left-4 top-[28px] h-[calc(100%-14px)] w-0.5 bg-[#4F46E5]"></div>
                              <div class="relative mt-0.5 ml-8">
                                <ng-container *ngTemplateOutlet="menuRecursivo; context: { 
                                  $implicit: menu.menus, 
                                  parentIndex: index,
                                  parentOption: menu.option,
                                  level: 1
                                }">
                                </ng-container>
                              </div>
                              }
                            </div>
                          </li>
                          }
                        </ul>
                        } @else {
                        <h6 class="text-zinc-400 font-light text-sm ml-[70px] text-center rounded-lg">
                          Você não tem nenhum menu. Adicione um acima!
                        </h6>
                        }
                      </div>
                    </div>
                  </div>

                  <ng-template #menuRecursivo let-children let-parentIndex="parentIndex" let-parentOption="parentOption"
                    let-level="level">
                    <ul class="relative space-y-0.5">
                      @for (child of children; track child; let currentIndex = $index; let last = $last) {
                      <li class="relative">
                        <div class="absolute -left-4 top-1/2 w-[33px] h-0.5 bg-[#4F46E5]"></div>

                        <div class="relative">
                          <div (click)="selectChatbotMenuOption(child)"
                            class="w-fit flex justify-between items-center rounded cursor-pointer transition-colors shadow-sm pr-2 pl-2 h-7"
                            [ngClass]="{
                            'bg-[#4F46E5] hover:bg-[#4338ca]': child.actived,
                            'bg-slate-500 hover:bg-slate-600': !child.actived
                          }">
                            <span class="flex items-center gap-1 whitespace-nowrap text-white">
                              <span class="min-w-[14px] font-medium text-xs">
                                {{ child.option.split('.').pop() }}
                              </span>
                              <span class="text-xs">{{ child.title }}</span>
                            </span>
                          </div>

                          @if (child.menus?.length > 0) {
                          <div class="absolute left-4 top-[28px] h-[calc(100%-14px)] w-0.5 bg-[#4F46E5]"></div>
                          <div class="mt-0.5 ml-8">
                            <ng-container *ngTemplateOutlet="menuRecursivo; context: { 
                              $implicit: child.menus, 
                              parentIndex: currentIndex,
                              parentOption: child.option,
                              level: level + 1
                            }">
                            </ng-container>
                          </div>
                          }
                        </div>
                      </li>
                      }
                    </ul>
                  </ng-template>
                </div>
              </div>

              <div class="w-full flex gap-2">

                <!-- Formulário do Menu -->
                <div class="w-full">
                  <ng-container formArrayName="menus">
                    @if (chatbotMenu && selectedFormGroup) {
                    <div class="h-[527px] w-full rounded-lg mt-[15px] border border-[#bdbdbd]"
                      [formGroup]="selectedFormGroup">
                      <div class="bg-[#f7f7f7] h-[50px] flex justify-between items-center pr-2 rounded-t-lg">
                        <div class="flex gap-2 ml-[15px]">
                          <h1 class="font-medium text-black">{{ chatbotMenu.option }}</h1>
                          <h1>{{ chatbotMenu.title }}</h1>
                        </div>

                        <div class="flex gap-2">
                          @if (isOptionWithDot(chatbotMenu.option)) {
                          <button
                            (click)="$event.stopPropagation(); addChildMenu(getParentIndex(chatbotMenu.option), chatbotMenu.option)"
                            class="bg-[#4F46E5] rounded h-8 w-8 flex items-center justify-center">
                            <mat-icon class="text-white text-base">add</mat-icon>
                          </button>

                          <button
                            (click)="$event.stopPropagation(); removeChildMenu(getParentIndex(chatbotMenu.option), getCurrentIndex(chatbotMenu.option), chatbotMenu.option)"
                            class="bg-red-600 rounded cursor-pointer h-8 w-8 flex items-center justify-center">
                            <mat-icon class="text-white text-base">delete</mat-icon>
                          </button>
                          }

                          @if (isOptionWithoutDot(chatbotMenu.option)) {
                          <button (click)="$event.stopPropagation(); addChildMenu(chatbotMenuIndex)"
                            class="bg-[#4F46E5] rounded h-8 w-8 flex items-center justify-center">
                            <mat-icon class="text-white text-base">add</mat-icon>
                          </button>

                          <button (click)="$event.stopPropagation(); removeMenu(chatbotMenuIndex)"
                            class="bg-red-600 rounded cursor-pointer h-8 w-8 flex items-center justify-center">
                            <mat-icon class="text-white text-base">delete</mat-icon>
                          </button>
                          }

                          <button (click)="activedChatBotMenu(chatbotMenu.option)"
                            class="rounded h-8 w-8 flex items-center justify-center"
                            [ngClass]="{ 'bg-[#4338ca]': getMenuActivedValue(chatbotMenu.option), 'bg-slate-500': !getMenuActivedValue(chatbotMenu.option) }">
                            <mat-icon class="text-white text-base">
                              {{ getMenuActivedValue(chatbotMenu.option) ? 'visibility' : 'visibility_off' }}
                            </mat-icon>
                          </button>

                        </div>

                      </div>

                      <div class="p-2 w-full h-full">
                        <div class="flex w-full gap-2"></div>

                        <div class="flex">
                          <div class="w-full h-full flex gap-6">
                            <div class="w-full h-full">
                              <div class="w-full">
                                <label class="font-semibold text-black mt-[10px]">Título</label>
                                <input type="text" formControlName="title" class="input-field">
                              </div>
                              <div class="w-full">
                                <label class="font-semibold text-black mt-[15px]">Mensagem</label>
                                <textarea formControlName="message" class="input-field"
                                  style="resize: none;"></textarea>
                              </div>

                              @if (shouldDisplayParent(chatbotMenu)) {
                              <div class="w-full flex flex-col justify-center items-center">
                                <div class="w-full">
                                  <label class="font-semibold text-black mt-[10px]">Departamento</label>
                                  <mat-select class="input-field" formControlName="department"
                                    (selectionChange)="onDepartmentChange($event.value, chatbotMenuIndex)"
                                    placeholder="Selecione">
                                    <div class="input-select-filter w-full p-2 mb-2">
                                      <input class="input-select-filter input-field" type="text"
                                        (focus)="onFocusDepartment()"
                                        [formControl]="getFilterControl(chatbotMenuIndex, 'department')"
                                        (input)="fetchFilter(getFilterControl(chatbotMenuIndex, 'department').value, chatbotMenuIndex, 'department')"
                                        placeholder="Pesquise por um departamento...">
                                    </div>
                                    <mat-option [value]="null">Nenhum</mat-option>
                                    @for (item of allDepartments; track item) {
                                    <mat-option [value]="item._id">{{item.name}}</mat-option>
                                    }
                                  </mat-select>
                                </div>
                                <div class="flex gap-2 w-full">
                                  <div class="w-full mt-[10px]">
                                    <label class="font-semibold text-black mt-[10px]">Atendente</label>
                                    <mat-select class="input-field" formControlName="user" placeholder="Selecione"
                                      [disabled]="!menus.get('department')?.value">
                                      <div class="input-select-filter w-full p-2 mb-2">
                                        <input class="input-select-filter input-field" type="text"
                                          (focus)="onFocusUser()"
                                          [formControl]="getFilterControl(chatbotMenuIndex, 'user')"
                                          (input)="fetchFilter(getFilterControl(chatbotMenuIndex, 'user').value, chatbotMenuIndex, 'user')"
                                          placeholder="Pesquise por um atendente...">
                                      </div>
                                      <mat-option *ngIf="departments" [value]="null">Nenhum</mat-option>
                                      @for (item of usersByMenu[generateMenuKey(chatbotMenuIndex)] || []; track item) {
                                      <mat-option [value]="item._id">{{item.name}}</mat-option>
                                      }
                                    </mat-select>
                                  </div>
                                  <div class="w-full mt-[10px]" *ngIf="settingsModule?.activeIAModule">
                                    <label class="font-semibold text-black mt-[10px]">Assistentes de IA</label>
                                    <mat-select class="input-field" formControlName="assistant" placeholder="Selecione"
                                      (selectionChange)="onSelectAssistant($event.value)">
                                      <mat-option [value]="null">Nenhum</mat-option>
                                      <mat-option *ngFor="let assistant of filteredAssistants" [value]="assistant.id">
                                        {{ assistant.name }}
                                      </mat-option>
                                    </mat-select>
                                  </div>
                                </div>

                              </div>
                              <div class="w-full mt-[10px]">
                                <label class="font-semibold text-black mt-[10px]">Tags</label>
                                <mat-select class="input-field" formControlName="tags" multiple placeholder="Selecione"
                                  [disableOptionCentering]="true">
                                  <div class="input-select-filter w-full p-2 mb-2">
                                    <input class="input-select-filter input-field" type="text" (focus)="onFocusTags()"
                                      [formControl]="getFilterControl(chatbotMenuIndex, 'tags')"
                                      (input)="fetchFilter(getFilterControl(chatbotMenuIndex, 'tags').value, chatbotMenuIndex, 'tags')"
                                      placeholder="Pesquise por tags...">
                                  </div>
                                  @for (item of allTags; track item) {
                                  <mat-option [value]="item._id">{{item.title}}</mat-option>
                                  }
                                </mat-select>
                              </div>
                              }
                            </div>

                            <div
                              class="w-[300px] h-[440px] bg-red rounded-lg flex flex-col justify-center items-center mt-[15px] cursor-pointer loading-container overflow-hidden"
                              (click)="fileInputFileChatbotMenu.click()">
                              <loading-component class="loading"
                                *ngIf="loadingSpinnerChatbotMenu$ | async"></loading-component>

                              @if (chatbotMenu.attachment) {
                              <ng-container>
                                <img [src]="attachmentChatbotMenu()" alt="Selected Media"
                                  class="w-full h-full object-cover rounded-lg" />
                              </ng-container>
                              } @else {
                              <ng-container class="flex flex-col items-center justify-center h-full">
                                <img
                                  src="https://firebasestorage.googleapis.com/v0/b/zapteck-2707e.appspot.com/o/chatbot%2FFeatured%20icon.png_1723483385408?alt=media&token=e92cba10-34cb-4aaa-9589-2e6dff8bd5cc"
                                  alt="Upload Icon" class="w-[100px] h-[80px] object-contain" />
                                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                  <span class="font-semibold text-[#4213F6] text-[17px]">Clique aqui para
                                    carregar</span>
                                </p>
                                <p class="text-[11px] text-gray-500 dark:text-gray-400">PNG, JPG, JPEG ou PDF (MAX.
                                  800x400px)</p>
                              </ng-container>
                              }

                              <input #fileInputFileChatbotMenu type="file"
                                (change)="onChangeFileChatbotMenu($event, chatbotMenuIndex)" style="display: none;" />

                              <div class="absolute top-2 right-2">
                                @if (chatbotMenu.attachment) {
                                <button
                                  (click)="removeAttachmentChatbotMenu(chatbotMenuIndex); $event.stopPropagation();"
                                  class="bg-red-600 text-white flex items-center rounded p-1">
                                  <mat-icon>clear</mat-icon>
                                </button>
                                }
                              </div>
                            </div>


                          </div>
                        </div>
                      </div>
                    </div>
                    }
                  </ng-container>
                </div>
              </div>
              <!-- <div class="bg-gray-100 p-2 mt-2" *ngIf="chatbotMenu">
                <p>Menu Selecionado: {{ chatbotMenu.option }} - {{ chatbotMenu.title }}</p>
                <p>Índice do Menu Pai: {{ chatbotMenuIndex }}</p>
              </div> -->
            </div>
          </div>
          <!-- } -->
        </div>
        <div class="flex justify-end items-end gap-2">
          <div class="flex flex-1 border-t-2 border-gray-200 justify-end items-center w-full gap-4 mt-3 mb-4">
            <button
              class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
              (click)="save()" *ngIf="isAdmin()">Salvar</button>
          </div>

        </div>
      </div>
    </form>
  </div>
</div>