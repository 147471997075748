import { ChangeDetectorRef, Component, ElementRef, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, Channel, Chatbot, ChatbotMenu, ChatbotService, Company, ConfirmationComponent, Department, DepartmentService, FirebaseService, HeaderTypeEnum, Integration, IntegrationService, IntegrationTypeEnum, OpenAIAssistant, OpenAIIntegration, Pager, ResponseService, ResponseTypeEnum, SettingsModule, SettingsModuleService, Tag, TagService, Team, TeamService, Traduct, UploadTypeEnum, User, UtilHelper } from 'lib-trend-core';
import { BehaviorSubject, map, Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'chatbot-component',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent extends AbstractComponent implements OnInit, OnChanges {
  private loadingSpinnerSubjectChatbotMenu = new BehaviorSubject<boolean>(false);
  private menuSubscription: Subscription;
  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);

  loadingSpinnerChatbotMenu$ = this.loadingSpinnerSubjectChatbotMenu.asObservable();
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();

  filterControls: FormArray = new FormArray([]);
  showContainerEmoji: boolean = false;
  translator!: Traduct;

  chatbot: Chatbot = <Chatbot>{};
  chatbotMenu: ChatbotMenu = <ChatbotMenu>{};
  chatbotMenuIndex: number;
  channels: Array<Channel>;

  departments: Array<Department> = new Array<Department>();
  allDepartments: Array<Department> = new Array<Department>();
  users: Array<User> = new Array<User>();
  allUsers: Array<User> = new Array<User>();
  usersByMenu: { [key: string]: any[] } = {};
  tags: Array<Tag> = new Array<Tag>();
  allTags: Array<Tag> = new Array<Tag>();

  chatbotMenuOption: string;
  pager: Pager<any> = new Pager<any>({ perPage: 10 });
  selectedFormGroup: FormGroup | null = null;

  @Input() channel: Channel;
  @ViewChild('messageTextarea') messageTextarea!: ElementRef<HTMLTextAreaElement>;
  settingsModule: SettingsModule = {} as SettingsModule;
  integration: Integration;

  filteredAssistants: OpenAIAssistant[] = [];
  constructor(
    private integrationService: IntegrationService,
    private service: SettingsModuleService,
    private dialog: MatDialog,
    private chatbotService: ChatbotService,
    private departmentService: DepartmentService,
    private tagService: TagService,
    private teamService: TeamService,
    private firebaseService: FirebaseService,
    public responseService: ResponseService,
    private cdRef: ChangeDetectorRef,

    injetor: Injector,
  ) {
    super(injetor)
    this.setupForm(<Chatbot>{});
  }

  ngOnInit(): void {
    this.getIntegrationById();
    this.settingsModule = {} as SettingsModule;
    this.getSettingsModule();
    this.getListDepartment();
    this.getListTag();
    this.getResponseAutomatic();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['channel']?.currentValue) {
      this.channel = changes['channel']?.currentValue;
      this.getChatbot();
    }
  }

  override ngOnDestroy(): void {
    if (this.menuSubscription) {
      this.menuSubscription.unsubscribe();
    }
  }

  private helperExtension(midia: string): 'image' | 'audio' | 'video' | 'document' | 'text' {
    const decodedUrl = decodeURIComponent(midia);

    const fileNameWithQuery = decodedUrl.split('/').pop() || '';
    const fileName = fileNameWithQuery.split('?')[0];

    const extension = fileName.split('.').pop()?.toLowerCase();

    const regex = /_(\d+)/;
    const nameWithoutPart = extension.replace(regex, '');

    switch (nameWithoutPart.trim()) {
      case 'mp4':
        return 'video';
      case 'pdf':
        return 'document';
      case 'mp3':
        return 'audio';
      case 'png':
        return 'image';
      case 'jpg':
        return 'image';
      case 'jpeg':
        return 'image'
      default:
        return 'text';
    }
  }

  private getListDepartment(): void {
    this.departmentService.getList().subscribe({
      next: (list: Array<Department>) => {
        this.allDepartments = list;
        this.departments = list;
      },
      error: (err) => this.alertService.error(err.error.message)
    });
  }

  private getResponseAutomatic() {
    this.searchParams = {
      company: this.getIDCurrentCompany(),
      type: ResponseTypeEnum.AUTOMATIC,
      channel: this.channel._id,
    };

    this.responseService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
      next: (value) => {
        this.pager = value;
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  private getListTag(): void {
    this.tagService.getList().subscribe({
      next: (list: Array<Tag>) => {
        this.allTags = list;
        this.tags = list;
      },
      error: (err) => this.alertService.error(err.error.message)
    });
  }

  private getChatbot(): void {
    this.chatbotService.getChatbotByCompanyAndChannel(this.getIDCurrentCompany(), this.channel._id).subscribe({
      next: (chatbot: Chatbot) => {
        if (!!chatbot) {
          this.isNew = false;
          this.chatbot = chatbot;
          this.setupForm(chatbot);
        } else {
          this.isNew = true;
          this.setupForm(<Chatbot>{});
        }
      },
      error: (err) => this.alertService.error(err.error.message)
    });
  }

  private setupForm(chatbot: Chatbot): void {
    this.formGroup = new FormGroup({
      _id: new FormControl(chatbot._id),
      message: new FormControl(chatbot.message, [Validators.required]),
      attachment: new FormControl(chatbot.attachment, []),
      type: new FormControl(chatbot.type ?? 'text', []),
      contentType: new FormControl(chatbot.contentType ?? 'text', []),
      menus: new FormArray([]),
      actived: new FormControl(chatbot.actived ?? false, []),
    });

    this.templateVariableList.forEach((variable, index) => {
      const controlName = 'message' + (index + 1);
      this.formGroup.addControl(controlName, new FormControl(''));
    });

    this.formGroup.valueChanges.subscribe(value => {
      this.chatbot = Object.assign(this.chatbot, value);
    });

    if (!!chatbot.menus && chatbot.menus.length > 0) {
      (this.formGroup.get('menus') as FormArray).clear();
      this.filterControls.clear();

      this.formGroup.get('assistant')?.valueChanges.subscribe(selectedAssistant => {
        this.onSelectAssistant(selectedAssistant);
      });

      chatbot.menus.forEach((element) => {
        const menuGroup = new FormGroup({
          option: new FormControl(element.option, []),
          title: new FormControl(element.title, []),
          attachment: new FormControl(element.attachment ?? null, []),
          message: new FormControl(element.message ?? null, []),
          department: new FormControl(element.department ?? null, []),
          user: new FormControl(element.user ?? null, []),
          assistant: new FormControl(element.assistant ?? null, []),
          integration: new FormControl(element.integration ?? null, []),
          tags: new FormControl(element.tags ?? null, []),
          actived: new FormControl(element.actived ?? false, []),
          type: new FormControl(element.type ?? null, []),
          contentType: new FormControl(element.contentType ?? null, []),
          menus: new FormArray([])
        });

        menuGroup.get('assistant')?.valueChanges.subscribe(selectedAssistant => {
          if (selectedAssistant && this.integration) {
            menuGroup.patchValue({ integration: this.integration._id });
          } else {
            menuGroup.patchValue({ integration: null });
          }
        });

        if (element.menus && element.menus.length > 0) {
          this.setupSubmenus(element.menus, menuGroup.get('menus') as FormArray);
        }

        (this.formGroup.get('menus') as FormArray).push(menuGroup);

        this.filterControls.push(
          new FormGroup({
            filterTag: new FormControl(''),
            filterDepartment: new FormControl(''),
            filterUser: new FormControl('')
          })
        );
      });
    }
    if (!this.isAdmin()) {
      this.formGroup.disable();
    }
  }

  private setupSubmenus(children: Array<ChatbotMenu>, formArray: FormArray) {
    children.forEach((child: ChatbotMenu) => {
      const childGroup = new FormGroup({
        option: new FormControl(child.option, []),
        title: new FormControl(child.title, []),
        attachment: new FormControl(child.attachment ?? null, []),
        message: new FormControl(child.message ?? null, []),
        department: new FormControl(child.department ?? null, []),
        user: new FormControl(child.user ?? null, []),
        assistant: new FormControl(child.assistant ?? null, []),
        integration: new FormControl(child.integration ?? null, []),
        tags: new FormControl(child.tags ?? null, []),
        actived: new FormControl(child.actived ?? false, []),
        type: new FormControl(child.type ?? null, []),
        contentType: new FormControl(child.contentType ?? null, []),
        menus: new FormArray([])
      });

      childGroup.get('assistant')?.valueChanges.subscribe(selectedAssistant => {
        if (selectedAssistant && this.integration) {
          childGroup.patchValue({ integration: this.integration._id });
        } else {
          childGroup.patchValue({ integration: null });
        }
      });

      if (child.menus && child.menus.length > 0) {
        this.setupSubmenus(child.menus, childGroup.get('menus') as FormArray);
      }

      formArray.push(childGroup);
    });
  }

  private removeMenuByOption(option: string, formArray: FormArray): boolean {
    for (let i = 0; i < formArray.length; i++) {
      const group = formArray.at(i) as FormGroup;
      if (group.get('option')?.value === option) {
        formArray.removeAt(i);
        return true;
      }
      const children = group.get('menus') as FormArray;
      if (children && children.length > 0) {
        if (this.removeMenuByOption(option, children)) {
          return true;
        }
      }
    }
    return false;
  }

  private removeFromChatbotData(option: string, menus: Array<ChatbotMenu>): boolean {
    for (let i = 0; i < menus.length; i++) {
      if (menus[i].option === option) {
        menus.splice(i, 1);
        return true;
      }
      if (menus[i].menus && menus[i].menus.length > 0) {
        if (this.removeFromChatbotData(option, menus[i].menus)) {
          return true;
        }
      }
    }
    return false;
  }

  private reorderMenuLevel(formArray: FormArray, parentOption: string = ''): void {
    formArray.controls.forEach((group, index) => {
      const newOption = parentOption ? `${parentOption}.${index + 1}` : `${index + 1}`;
      (group as FormGroup).get('option')?.setValue(newOption);

      const children = (group as FormGroup).get('children') as FormArray;
      if (children && children.length > 0) {
        this.reorderMenuLevel(children, newOption);
      }
    });
  }

  private reorderChatbotMenuLevel(menus: Array<ChatbotMenu>, parentOption: string = ''): void {
    menus.forEach((menu, index) => {
      const newOption = parentOption ? `${parentOption}.${index + 1}` : `${index + 1}`;
      menu.option = newOption;

      if (menu.menus && menu.menus.length > 0) {
        this.reorderChatbotMenuLevel(menu.menus, newOption);
      }
    });
  }

  private reorderFullMenuStructure(): void {
    this.reorderMenuLevel(this.formGroup.get('menus') as FormArray);
    this.reorderChatbotMenuLevel(this.chatbot.menus);
  }

  private findMenuInChatbot(option: string, menus: Array<ChatbotMenu> = this.chatbot.menus): any {
    for (const menu of menus) {
      if (menu.option === option) {
        return menu;
      }
      if (menu.menus && menu.menus.length > 0) {
        const found = this.findMenuInChatbot(option, menu.menus);
        if (found) return found;
      }
    }
    return null;
  }

  private findFormGroupByOption(option: string, formArray?: FormArray): { group: FormGroup, parent: FormArray } | null {
    if (!formArray) {
      formArray = this.formGroup.get('menus') as FormArray;
    }
    for (let i = 0; i < formArray.length; i++) {
      const group = formArray.at(i) as FormGroup;
      if (group.get('option')?.value === option) {
        return { group, parent: formArray };
      }
      const children = group.get('menus') as FormArray;
      if (children && children.length > 0) {
        const found = this.findFormGroupByOption(option, children);
        if (found) return found;
      }
    }
    return null;
  }

  private generateNextOption(parentFormGroup: FormGroup, children: FormArray): string {
    const parentOption = parentFormGroup.get('option')?.value || '';
    const existingChildren = children ? Array.from(children.controls) : [];

    if (existingChildren.length === 0) {
      return `${parentOption}.1`;
    }

    const existingOptions = existingChildren.map(control =>
      (control as FormGroup).get('option')?.value
    ).filter(Boolean);

    let maxNumber = 0;
    existingOptions.forEach(option => {
      if (!option) return;
      const parts = option.split('.');
      const lastNumber = parseInt(parts[parts.length - 1], 10);
      if (!isNaN(lastNumber) && lastNumber > maxNumber) {
        maxNumber = lastNumber;
      }
    });

    return `${parentOption}.${maxNumber + 1}`;
  }

  private updateMenuInChatbot(option: string, newValues: any) {

    const updateMenuRecursive = (menus: Array<ChatbotMenu>): boolean => {
      for (let menu of menus) {
        if (String(menu.option) === option) {
          const originalOption = menu.option;
          const originalChildren = menu.menus;

          Object.assign(menu, newValues);

          menu.option = originalOption;
          if (originalChildren) {
            menu.menus = originalChildren;
          }

          return true;
        }
        if (menu.menus && menu.menus.length > 0) {
          if (updateMenuRecursive(menu.menus)) {
            return true;
          }
        }
      }
      return false;
    };

    if (this.chatbot.menus) {
      if (updateMenuRecursive(this.chatbot.menus)) {
        if (this.chatbot._id) {
          this.chatbotService.update(this.chatbot._id, this.chatbot).subscribe({
            next: () => {
              this.cdRef.detectChanges();
            },
            error: (err) => {
              this.alertService.error('Erro ao atualizar o chatbot');
            }
          });
        }
      }
    }
  }

  private updateChatbotMenuAttachment(option: string, url: string, contentType: string) {
    const updateMenuRecursive = (menus: Array<ChatbotMenu>): boolean => {
      for (let menu of menus) {
        if (String(menu.option) === option) {
          menu.attachment = url;
          menu.type = this.helperExtension(url);
          menu.contentType = contentType;
          return true;
        }
        if (menu.menus && menu.menus.length > 0) {
          if (updateMenuRecursive(menu.menus)) {
            return true;
          }
        }
      }
      return false;
    };

    if (this.chatbot.menus) {
      updateMenuRecursive(this.chatbot.menus);
      this.formGroup.patchValue({ menus: this.chatbot.menus });
    }
  }

  private loadUsersByDepartment(idDepartment: string, menuKey: string) {
    if (!idDepartment) {
      this.usersByMenu[menuKey] = [];
      return;
    }

    this.teamService.getByDepartment(idDepartment).subscribe({
      next: (teams: Team[]) => {
        this.usersByMenu[menuKey] = teams.flatMap((team: Team) => team.agents);
        this.cdRef.detectChanges();
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  get menus() {
    return this.formGroup.controls['menus'] as FormArray;
  }

  onDepartmentChange(selectedDepartment: string, parentIndex: number, childIndex?: number) {
    const menuKey = this.generateMenuKey(parentIndex, childIndex);
    this.loadUsersByDepartment(selectedDepartment, menuKey);
  }

  generateMenuKey(parentIndex: number, childIndex?: number): string {
    return childIndex !== undefined ? `${parentIndex}_${childIndex}` : `${parentIndex}`;
  }

  fetchFilter(value: string, index: number, filterType: 'tags' | 'department' | 'user'): void {
    switch (filterType) {
      case 'tags': {
        const currentControl = (this.menus.at(index) as FormGroup).get('tags');
        const currentValue = currentControl?.value || [];

        this.allTags = this.tags.filter(tag =>
          tag.title.toLowerCase().includes(value.toLowerCase()) &&
          !currentValue.includes(tag._id)
        );

        currentValue.forEach(tagId => {
          const existingTag = this.tags.find(tag => tag._id === tagId);
          if (existingTag) {
            this.allTags.push(existingTag);
          }
        });
        break;
      };
      case 'department': {
        const currentControl = (this.menus.at(index) as FormGroup).get('department');
        const currentValue = currentControl?.value || null;


        this.allDepartments = this.departments.filter(department =>
          department.name.toLowerCase().includes(value.toLowerCase()) &&
          department._id !== currentValue
        );

        const existingDepartment = this.departments.find(department => department._id === currentValue);
        if (existingDepartment) {
          this.allDepartments.push(existingDepartment);
        }
        break;
      };
      default: {
        const currentControl = (this.menus.at(index) as FormGroup).get('user');
        const currentValue = currentControl?.value || null;


        this.allUsers = this.users.filter(user =>
          user.name.toLowerCase().includes(value.toLowerCase()) &&
          user._id !== currentValue
        );

        const existingUsers = this.users.find(user => user._id === currentValue);
        if (existingUsers) {
          this.allUsers.push(existingUsers);
        }
        break;
      };
    }
  }

  getFilterControl(index: number, filterType: 'tags' | 'department' | 'user'): FormControl {
    switch (filterType) {
      case 'tags': {
        return this.filterControls.at(index).get('filterTag') as FormControl;
      };
      case 'department': {
        return this.filterControls.at(index).get('filterDepartment') as FormControl;
      };
      default: {
        return this.filterControls.at(index).get('filterUser') as FormControl;
      }
    }
  }

  onFocusDepartment(): void {
    this.allDepartments = this.departments;
  }

  onFocusTags(): void {
    this.allTags = this.tags;
  }

  onFocusUser(): void {
    this.allUsers = this.users;
  }

  addMenu(): void {
    const nextOption = (this.chatbot?.menus?.length + 1 || 1).toString();
    (this.formGroup.get('menus') as FormArray).push(
      new FormGroup({
        option: new FormControl(nextOption, []),
        title: new FormControl('Novo item ', []),
        attachment: new FormControl(null, []),
        message: new FormControl(null, []),
        department: new FormControl(null, []),
        user: new FormControl(null, []),
        assistant: new FormControl(null, []),
        integration: new FormControl(null, []),
        tags: new FormControl(null, []),
        actived: new FormControl(true, []),
        type: new FormControl(null, []),
        contentType: new FormControl(null, []),
        menus: new FormArray([]),
      })
    );

    this.filterControls.push(
      new FormGroup({
        filterTag: new FormControl(''),
        filterDepartment: new FormControl(''),
        filterUser: new FormControl(''),
        filterAssistants: new FormControl(''),
      })
    );
  }

  addChildMenu(parentIndex: number, parentOption: string = '') {
    const menus = this.formGroup.get('menus') as FormArray;
    let parentFormGroup: FormGroup;
    let parentChatbotMenu: any;

    if (parentIndex >= 0 && !parentOption) {
      parentFormGroup = menus.at(parentIndex) as FormGroup;
      parentChatbotMenu = this.chatbot.menus[parentIndex];
    } else {
      const parentFormResult = this.findFormGroupByOption(parentOption, menus);
      if (!parentFormResult) {
        return;
      }
      parentFormGroup = parentFormResult.group;
      parentChatbotMenu = this.findMenuInChatbot(parentOption);
    }

    if (!parentFormGroup || !parentChatbotMenu) {
      return;
    }

    parentFormGroup.get('department')?.setValue(null);
    parentFormGroup.get('user')?.setValue(null);
    parentFormGroup.get('assistant')?.setValue(null);
    parentFormGroup.get('integration')?.setValue(null);
    parentFormGroup.get('tags')?.setValue([]);

    parentChatbotMenu.department = null;
    parentChatbotMenu.user = null;
    parentChatbotMenu.tags = [];
    parentChatbotMenu.assistant = null;
    parentChatbotMenu.integration = null;


    let childMenus = parentFormGroup.get('menus') as FormArray;
    if (!childMenus) {
      childMenus = new FormArray([]);
      parentFormGroup.setControl('menus', childMenus);
    }

    const nextOption = this.generateNextOption(parentFormGroup, childMenus);
    const newActived = parentChatbotMenu.actived;

    const newMenuItem = this.formBuilder.group({
      option: [nextOption],
      title: ['Novo item'],
      message: [''],
      department: [null],
      user: [null],
      assistant: [null],
      integration: [null],
      tags: [[]],
      attachment: [null],
      actived: [newActived],
      type: [null],
      contentType: [null],
      menus: this.formBuilder.array([])
    });

    childMenus.push(newMenuItem);

    const newMenuData = {
      option: nextOption,
      title: 'Novo item ' + nextOption,
      message: '',
      department: null,
      user: null,
      assistant: null,
      integration: null,
      tags: [],
      attachment: null,
      actived: newActived,
      type: null,
      contentType: null,
      menus: []
    };

    if (!parentChatbotMenu.menus) {
      parentChatbotMenu.menus = [];
    }
    parentChatbotMenu.menus.push(newMenuData);

    if (this.chatbot._id) {
      this.chatbotService.update(this.chatbot._id, this.chatbot).subscribe({
        next: () => {
          this.cdRef.detectChanges();
        },
        error: (err) => {
          this.alertService.error('Erro ao atualizar o chatbot');
        }
      });
    }
  }

  getParentIndex(option: string | number): number {
    if (typeof option !== 'string') return -1;

    try {
      const parentOption = option.split('.').slice(0, -1).join('.');
      return this.chatbot.menus.findIndex(menu => menu.option === parentOption);
    } catch (error) {
      return -1;
    }
  }

  getCurrentIndex(option: string): number {
    const parentOption = option.split('.').slice(0, -1).join('.');
    const parent = this.chatbot.menus.find(menu => menu.option === parentOption);
    if (parent && parent.menus) {
      return parent.menus.findIndex(child => child.option === option);
    }
    return -1;
  }

  removeMenu(index: number) {
    console.log('estou aqui')
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        const menus = this.formGroup.get('menus') as FormArray;
        const menuToRemove = menus.at(index) as FormGroup;
        const menuOption = menuToRemove.get('option')?.value;

        const previousMenu = this.findPreviousMenu(menuOption, menus);

        menus.removeAt(index);
        this.chatbot.menus.splice(index, 1);

        this.reorderFullMenuStructure();

        if (previousMenu) {
          const previousOption = previousMenu.get('option')?.value;
          const previousChatbotMenu = this.findMenuInChatbot(previousOption);
          if (previousChatbotMenu) {
            this.selectChatbotMenuOption(previousChatbotMenu);
          }
        } else {
          this.selectedFormGroup = null;
          this.chatbotMenu = null;
          this.chatbotMenuOption = '';
        }

        if (this.chatbot._id) {
          this.chatbotService.update(this.chatbot._id, this.chatbot).subscribe({
            next: () => {
              this.cdRef.detectChanges();
            },
            error: (err) => {
              this.alertService.error('Erro ao atualizar o chatbot');
              console.error('Erro ao atualizar chatbot:', err);
            }
          });
        }
      }
    });
  }

  removeChildMenu(parentIndex: number, childIndex: number, childOption: string): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        const menus = this.formGroup.get('menus') as FormArray;

        const previousMenu = this.findPreviousMenu(childOption, menus);

        this.removeMenuByOption(childOption, menus);
        this.removeFromChatbotData(childOption, this.chatbot.menus);
        this.reorderFullMenuStructure();

        if (previousMenu) {
          const previousOption = previousMenu.get('option')?.value;
          const previousChatbotMenu = this.findMenuInChatbot(previousOption);
          if (previousChatbotMenu) {
            this.selectChatbotMenuOption(previousChatbotMenu);
          }
        } else {
          this.selectedFormGroup = null;
          this.chatbotMenu = null;
          this.chatbotMenuOption = '';
        }

        if (this.chatbot._id) {
          this.chatbotService.update(this.chatbot._id, this.chatbot).subscribe({
            next: () => {
              this.cdRef.detectChanges();
            },
            error: (err) => {
              this.alertService.error('Erro ao atualizar o chatbot');
            }
          });
        }
      }
    });
  }

  findPreviousMenu(currentOption: string, menus: FormArray): FormGroup | null {
    if (!currentOption) return null;

    const parts = currentOption.toString().split('.');

    if (parts.length === 1) {
      const currentIndex = parseInt(currentOption) - 1;
      if (currentIndex > 0) {
        return menus.at(currentIndex - 1) as FormGroup;
      }
      return null;
    }

    const parentOption = parts.slice(0, -1).join('.');
    const currentIndex = parseInt(parts[parts.length - 1]);

    const parentGroup = this.findFormGroupByOption(parentOption, menus)?.group;
    if (!parentGroup) return null;

    const siblings = parentGroup.get('menus') as FormArray;

    if (currentIndex > 1) {
      return siblings.at(currentIndex - 2) as FormGroup;
    }

    return parentGroup;
  }


  selectChatbotMenuOption(menu: ChatbotMenu) {
    const optionStr = String(menu.option).trim();

    const formGroupSelected = this.getFormGroupByOption(optionStr);
    if (formGroupSelected) {
      if (this.menuSubscription) {
        this.menuSubscription.unsubscribe();
      }

      this.chatbotMenu = menu;
      this.chatbotMenuOption = optionStr;
      this.selectedFormGroup = formGroupSelected;

      this.menuSubscription = this.selectedFormGroup.valueChanges
        .subscribe(changes => {
          this.updateMenuInChatbot(optionStr, changes);
        });

      const topLevelOption = optionStr.includes('.') ? optionStr.split('.')[0] : optionStr;
      const menusArray = this.formGroup.get('menus') as FormArray;
      const index = menusArray.controls.findIndex((group: FormGroup) => {
        return String(group.get('option')?.value).trim() === topLevelOption;
      });
      this.chatbotMenuIndex = index >= 0 ? index : -1;
      this.onDepartmentChange(this.selectedFormGroup.get('department')?.value, index);
    } else {
      console.warn("FormGroup não encontrado para a opção:", menu.option);
    }
    this.cdRef.detectChanges();
  }

  getSelectedFormGroup(): FormGroup | null {
    return this.selectedFormGroup;
  }

  getFormGroupByOption(option: string, formArray?: FormArray): FormGroup | null {
    if (!formArray) {
      formArray = this.formGroup.get('menus') as FormArray;
    }
    for (let i = 0; i < formArray.length; i++) {
      const group = formArray.at(i) as FormGroup;
      const groupOption = String(group.get('option')?.value).trim();
      if (groupOption === option.trim()) {
        return group;
      }
      const childrenArray = group.get('menus') as FormArray;
      if (childrenArray && childrenArray.length > 0) {
        const found = this.getFormGroupByOption(option, childrenArray);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  findMenuIndex(menu: ChatbotMenu): number {
    return this.menus.controls.findIndex(m => m.value.option === menu.option);
  }

  clearMenuSelection() {
    this.chatbotMenu = null;
    this.chatbotMenuIndex = -1;
    this.selectedFormGroup = null;
    this.cdRef.detectChanges();
  }

  isMenuSelected(index: number): boolean {
    return this.chatbotMenuIndex === index;
  }

  onChangeFileChatbotMenu(event: any, index: number): void {
    const file: File = event.target.files[0];
    if (file && this.selectedFormGroup) {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        try {
          this.loadingSpinnerSubjectChatbotMenu.next(true);
          const filename = file.name + '_' + new Date().getTime();
          const contentType = file.type;
          this.firebaseService.uploadFile(file, filename, UploadTypeEnum.CHATBOT).then((snapshot: { url: string }) => {
            this.loadingSpinnerSubjectChatbotMenu.next(false);

            this.selectedFormGroup.patchValue({
              attachment: snapshot.url,
              type: this.helperExtension(snapshot.url),
              contentType: contentType
            });

            const optionStr = this.chatbotMenu.option;
            this.updateChatbotMenuAttachment(optionStr, snapshot.url, contentType);
          });
        } catch (error) {
          this.loadingSpinnerSubjectChatbotMenu.next(false);
          this.alertService.error('Ops! Não foi possível fazer upload do seu arquivo.');
        }
      };
      reader.readAsDataURL(file);
    }
  }

  onMidiaChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) return;

    const file = input.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        try {
          this.loadingSpinnerSubject.next(true);
          this.loading = true;
          const filename = file.name + '_' + new Date().getTime();
          const contentType = file.type;
          this.firebaseService.uploadFile(file, filename, UploadTypeEnum.CHATBOT).then((snapshot: { url: string }) => {
            this.loadingSpinnerSubject.next(false);
            this.loading = false;
            const type = this.helperExtension(snapshot.url);
            this.formGroup.patchValue({ attachment: snapshot.url, type, contentType });
          });
        } catch (error) { }
      };
      reader.readAsArrayBuffer(file);
    }
  }

  removeAttachment(): void {
    if (this.chatbot.attachment) {
      this.chatbot.attachment = null;
      this.formGroup.patchValue({ attachment: null });

      if (this.chatbot._id) {
        this.chatbotService.update(this.chatbot._id, this.chatbot).subscribe({
          next: () => this.alertService.success('Arquivo removido.'),
          error: (err) => this.alertService.error(`Erro ao atualizar banco de dados: ${err.error.message}`),
        });
      } else {
        this.alertService.error('ID do chatbot não encontrado.');
      }
    }
  }

  removeAttachmentChatbotMenu(index: number): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true && this.selectedFormGroup && this.chatbotMenu) {
        const attachment = this.selectedFormGroup.get('attachment')?.value;
        if (attachment) {
          this.firebaseService.deleteFile(attachment)
            .then(() => {
              this.selectedFormGroup.patchValue({
                attachment: null,
                type: null,
                contentType: null
              });

              const optionStr = this.chatbotMenu.option;
              this.updateChatbotMenuAttachment(optionStr, null, null);

              if (this.chatbot._id) {
                this.chatbotService.update(this.chatbot._id, this.chatbot).subscribe({
                  next: () => this.alertService.success('Arquivo removido.'),
                  error: (err) => this.alertService.error(`Erro ao atualizar banco de dados: ${err.error.message}`),
                });
              } else {
                this.alertService.error('ID do chatbot não encontrado.');
              }
            })
            .catch(error => {
              this.alertService.error('Ops! Não foi possível deletar esse arquivo.');
            });
        }
      }
    });
  }

  attachmentChatbotMenu() {
    if (this.chatbotMenu.attachment) {
      const mediaType = this.helperExtension(this.chatbotMenu.attachment);

      switch (mediaType) {
        case 'image':
          return this.chatbotMenu.attachment;
        case 'audio':
          return UtilHelper.on_audio;
        case 'video':
          return UtilHelper.on_video;
        case 'document':
          return UtilHelper.on_pdf;
        default:
          return UtilHelper.on_file;
      }
    } else {
      return UtilHelper.no_image_upload;
    }
  }

  attachmentChatbot(): string {
    const midia = this.formGroup.get('attachment')?.value;

    if (midia) {
      const mediaType = this.helperExtension(midia);

      switch (mediaType) {
        case 'image':
          return midia;
        case 'audio':
          return UtilHelper.on_audio;
        case 'video':
          return UtilHelper.on_video;
        case 'document':
          return UtilHelper.on_pdf;
        default:
          return UtilHelper.on_file;
      }
    } else {
      return UtilHelper.no_image_upload;
    }
  }

  getSettingsModule() {
    this.service.getByChannel(this.channel._id).subscribe({
      next: (value: SettingsModule) => {
        this.settingsModule = value;
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  save() {
    const isWelcomeActive = this.pager.list.some(item =>
      item.type === ResponseTypeEnum.AUTOMATIC &&
      item.header === HeaderTypeEnum.WELCOME &&
      item.activated === true
    );

    if (isWelcomeActive) {
      this.alertService.info('O chatbot não pode ser ativado enquanto a mensagem de boas-vindas está ativa.');
      return;
    }


    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }


    const formValue = this.formGroup.value;
    this.chatbot = {
      ...this.chatbot,
      ...formValue,
      company: { _id: super.getIDCurrentCompany() } as Company,
      channel: { _id: this.channel._id } as Channel
    };


    this.chatbotService.update(this.chatbot._id, this.chatbot).subscribe({
      next: (value) => {
        this.alertService.success('Chatbot atualizado com sucesso.');
        this.cdRef.detectChanges();
      }
    });
  }

  shouldDisplayParent(menu: ChatbotMenu): boolean {
    if (!menu.menus || menu.menus.length === 0) {
      return true;
    }
    return menu.menus.every(child => !child.actived);
  }

  findMenuAndParent(option: string, menus: Array<ChatbotMenu>, parent: any = null): { menu: ChatbotMenu; parent: any } | null {
    for (const menu of menus) {
      if (menu.option === option) {
        return { menu, parent };
      }
      if (menu.menus && menu.menus.length > 0) {
        const found = this.findMenuAndParent(option, menu.menus, menu);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  activedChatBotMenu(option: string): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        const menuResult = this.findMenuAndParent(option, this.chatbot.menus);
        const targetMenu = menuResult?.menu;
        if (!targetMenu) return;

        const newActiveState = !targetMenu.actived;
        console.log(`Alterando estado do menu: ${targetMenu.option} para ${newActiveState}`);

        targetMenu.actived = newActiveState;
        this.updateChildrenActivation(targetMenu, newActiveState);

        if (newActiveState) {
          this.updateParentActivation(option);
        }

        console.log("Estrutura atualizada do menu:", JSON.stringify(this.chatbot.menus, null, 2));

        this.formGroup.patchValue({ menus: this.chatbot.menus });
        
        if (this.chatbotMenu && this.chatbotMenu.option === option) {
          this.chatbotMenu = targetMenu;
          
          if (this.selectedFormGroup) {
            this.selectedFormGroup.patchValue({
              actived: newActiveState
            });
          }
        }

        if (this.chatbot._id) {
          this.chatbotService.update(this.chatbot._id, this.chatbot).subscribe({
            next: () => {
              this.cdRef.detectChanges();
              
              if (this.chatbotMenu && this.chatbotMenu.option === option) {
                this.selectChatbotMenuOption(this.chatbotMenu);
              }
            },
            error: (err) => this.alertService.error('Erro ao atualizar o chatbot'),
          });
        }
      }
    });
  }

  updateChildrenActivation(menu: ChatbotMenu, activeState: boolean): void {
    if (menu.menus && menu.menus.length > 0) {
      for (const child of menu.menus) {
        console.log(`Alterando estado do filho: ${child.option} para ${activeState}`);
        child.actived = activeState;
        this.updateChildrenActivation(child, activeState);
      }
    }
  }

  updateParentActivation(childOption: string): void {
    const parentResult = this.findMenuAndParent(childOption, this.chatbot.menus);
    if (parentResult && parentResult.parent) {
      if (!parentResult.parent.actived) {
        console.log(`Ativando o pai: ${parentResult.parent.option} por conta do filho ${childOption}`);
        parentResult.parent.actived = true;
      }
      this.updateParentActivation(parentResult.parent.option);
    }
  }

  getMenuActivedValue(option: string): boolean {
    const menuResult = this.findMenuAndParent(option, this.chatbot.menus);
    return menuResult?.menu?.actived ?? false;
  }

  setVariableInContent(variable: string): void {
    const control = this.formGroup.get('message') as FormControl;
    if (control) {
      const currentValue = control.value || '';
      control.setValue(currentValue + ' ' + variable);
    }
  }

  applyFormat(format: string) {
    const textarea = this.messageTextarea.nativeElement as HTMLTextAreaElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = textarea.value.substring(start, end);

    if (!selectedText) return;

    let formattedText = '';

    switch (format) {
      case 'bold':
        formattedText = `*${selectedText}*`;
        break;
      case 'italic':
        formattedText = `_${selectedText}_`;
        break;
      case 'underline':
        formattedText = `~${selectedText}~`;
        break;
      default:
        return;
    }

    textarea.value =
      textarea.value.substring(0, start) +
      formattedText +
      textarea.value.substring(end);

    textarea.setSelectionRange(start + formattedText.length, start + formattedText.length);
    textarea.focus();
    this.updateMessage();
  }

  updateMessage() {
    const textarea = this.messageTextarea.nativeElement as HTMLTextAreaElement;
    const messageWithoutLineBreaks = textarea.value;
    this.formGroup.patchValue({ message: messageWithoutLineBreaks });
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');

    pastedData = pastedData
      .replace(/<p[^>]*>/g, '\n')
      .replace(/<\/p>/g, '\n\n')
      .replace(/<li[^>]*>/g, '')
      .replace(/<\/li>/g, '\n')
      .replace(/<\/?html[^>]*>/g, '')
      .replace(/<\/?body[^>]*>/g, '')
      .replace(/<!--.*?-->/g, '')
      .replace(/<\/?br[^>]*>/g, '\n')
      .replace(/<\/?strong[^>]*>/g, '*')
      .replace(/<\/?b[^>]*>/g, '*')
      .replace(/<\/?em[^>]*>/g, '_')
      .replace(/<\/?i[^>]*>/g, '_')
      .replace(/<\/?u[^>]*>/g, '~$&~');

    pastedData = pastedData.replace(/\n{2,}/g, '\n\n').trim();

    const textarea = this.messageTextarea.nativeElement as HTMLTextAreaElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    textarea.value =
      textarea.value.substring(0, start) +
      pastedData +
      textarea.value.substring(end);

    textarea.setSelectionRange(start + pastedData.length, start + pastedData.length);
    textarea.focus();
    this.updateMessage();
  }

  toogleContainerEmoji(): void {
    this.showContainerEmoji = !this.showContainerEmoji;
  }

  addEmoji(event: any): void {
    const messageFg = this.formGroup.get('message');
    const currentText = messageFg.value || '';

    messageFg.setValue(currentText + ' ' + event.emoji.native);
  }

  getDisplayOption(option: any): string {
    if (typeof option === 'string') {
      return option.split('.').pop() || option;
    }
    return option;
  }

  isOptionWithDot(option: string | number): boolean {
    return typeof option === 'string' && option.includes('.');
  }

  isOptionWithoutDot(option: string | number): boolean {
    return typeof option !== 'string' || !option.includes('.');
  }

  getIntegrationById(): void {
    this.integrationService.getList()
      .pipe(
        takeUntil(this.destroy$),
        map((integrations: Integration[]) =>
          integrations.map(integration => {
            if (integration.type === IntegrationTypeEnum.OPENAI) {
              const openAIMetadata = integration.metadata as OpenAIIntegration;
              const filteredAssistants = openAIMetadata.assistants?.filter(assistant =>
                assistant.channels.some(channel => channel._id === this.channel._id)
              ) || [];
              return {
                ...integration,
                metadata: {
                  ...openAIMetadata,
                  assistants: filteredAssistants
                }
              };
            }
            return integration;
          })
        )
      )
      .subscribe({
        next: (integrations: Integration[]) => {
          const openaiIntegration = integrations.find(integration =>
            integration.type === IntegrationTypeEnum.OPENAI &&
            (integration.metadata as OpenAIIntegration).assistants?.length
          );
          this.integration = openaiIntegration;
          if (openaiIntegration) {
            this.filteredAssistants = (openaiIntegration.metadata as OpenAIIntegration).assistants || [];
          } else {
            this.filteredAssistants = [];
          }
        },
        error: (err) => this.alertService.error(err.error.message)
      });
  }

  onSelectAssistant(selectedAssistantId: string): void {
    this.formGroup.patchValue({
      assistant: selectedAssistantId,
      integration: this.integration?._id || null
    });
  }
}
